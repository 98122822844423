<template>
  <div class="admin-submenu-section section">
    <div class="container">
      <div v-if="menu" class="m-b">
        <router-link :to="backRouter" class="has-text-weight-bold has-text-white">
          <IconBase width="14" height="12" icon-name="back" class="m-b-0 m-r-2x">
            <IconBack />
          </IconBase>
          <span>{{ menu.title }}</span>
        </router-link>
      </div>
      <div class="columns is-align-bottom is-gapless is-multiline">
        <div class="column is-narrow m-r max-w-full">
          <h1
            class="has-text-weight-bold has-text-white is-size-3"
            :class="{ 'm-b-3x': !$slots.description }"
          >
            <slot />
          </h1>
          <p v-if="!!$slots.description" class="has-text-white m-b-3x" style="margin-top: -5px">
            <slot name="description" />
          </p>
        </div>
        <div class="column is-narrow m-l-auto">
          <div class="admin-submenu-outer">
            <ul class="admin-submenu">
              <li v-for="r in subMenu" :key="r.name" class="admin-submenu-item">
                <router-link :to="{ name: r.name, params: { ...$route.params } }">
                  {{ r.title }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBack from '@/components/icons/IconBack';

export default {
  name: 'AdminSubMenu',
  components: { IconBack },
  props: {
    menu: {
      type: Object,
      required: true,
    },
    subMenu: {
      type: Array,
      required: true,
    },
  },
  computed: {
    backRouter() {
      if (this.menu.query) {
        return { name: this.menu.name, query: this.menu.query };
      }

      return { name: this.menu.name };
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-submenu-outer {
  overflow-x: auto;
}
.max-w-full {
  max-width: 100%;
}
</style>
